<template>
  <div class="outer-page">
    <x-table :no-data-text="CA('maintenanceInspection_check') ? '暂无数据' : '暂无数据查看权限'" :columns="table.columns" :data="table.data" :loading="table.loading" :config="config" @search="search" @add="add" @page-change="pageChange" @loadExpend="loadExpend" @page-size-change="pageSizeChange"></x-table>
    <Modal v-model="modal.show" :title="modal.title" :width="900" @on-visible-change="modalChange">
      <Form ref="form" :model="form" :label-width="140" :rules="rules">
        <Row>
          <Col span="24">
          <FormItem label="检查图片">
            <upload-image :max="1" :clear-flag="!modal.show" v-model="form.inspectPic"></upload-image>
          </FormItem>
          </Col>
          <Col span="24">
          <FormItem label="设备名称" prop="deviceName">
            <Input clearable placeholder="请输入设备名称" v-model="form.deviceName" style="width: 80%"></Input>
          </FormItem>
          </Col>
          <Col span="24">
          <FormItem label="检查日期" prop="inspectTime">
            <DatePicker type="date" placeholder="请选择检查日期" v-model="form.inspectTime" style="width: 80%"></DatePicker>
          </FormItem>
          </Col>
          <Col span="24">
          <FormItem label="检查人" prop="inspectPeople">
            <Input clearable placeholder="请输入检查人" v-model="form.inspectPeople" style="width: 80%"></Input>
          </FormItem>
          </Col>
          <Col span="24">
          <FormItem label="检查人电话" prop="inspectTel">
            <Input clearable placeholder="请输入检查人电话" v-model="form.inspectTel" style="width: 80%"></Input>
          </FormItem>
          </Col>
          <Col span="24">
          <FormItem label="是否损坏" prop="deviceStatus">
            <Select clearable placeholder="请选择是否损坏" v-model="form.deviceStatus" style="width: 80%">
              <Option value="0">否</Option>
              <Option value="1">是</Option>
            </Select>
          </FormItem>
          </Col>
          <Col span="24">
          <FormItem label="巡检状况" prop="deviceCondition">
            <Input type="textarea" :rows="4" placeholder="请输入巡检状况" v-model="form.deviceCondition" style="width: 80%"></Input>
          </FormItem>
          </Col>
          <Col span="24">
          <FormItem label="备注">
            <Input type="textarea" :rows="4" placeholder="请输入备注" style="width: 80%" v-model="form.remarks"></Input>
          </FormItem>
          </Col>
        </Row>
      </Form>
      <p slot="footer" style="text-align: center">
        <Button type="primary" @click="submit">提交</Button>
        <Button @click="() => (modal.show = false)">取消</Button>
      </p>
    </Modal>
    <farmers-detail v-model="farmersDetail.show" :farmersDetailInfo="farmersDetail.info" :farmListInfo="farmListInfo" :servicePathInfo="servicePathInfo"></farmers-detail>
    <pic-view v-model="checkPic" :src="imgSrc"></pic-view>
    <Modal :width="300" v-model="isDelete" title="是否删除" @on-ok="deletes" @on-cancel="
        () => {
          this.isDelete = false;
        }
      ">
      <p>确定删除该条消息？</p>
    </Modal>
    <driver-bind v-model="driverDetail.show" :driverInfo="driverDetail.info"></driver-bind>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import picView from '@/components/picture_view'
import CU from '@/common/util'
import farmersDetail from './farmersDetail.vue'
import driverBind from './farmersBind'
import { host } from '@/common/http/host'
export default {
  name: '',
  components: {
    picView,
    farmersDetail,
    driverBind,
  },
  data() {
    return {
      table: {
        columns: [
          {
            title: '所属镇街',
            minWidth: 180,
            tooltip: true,
            align: 'center',
            key: 'areaName',
          },
          {
            title: '检查图片',
            align: 'center',
            width: 100,
            render: (h, { row }) => {
              if (row.inspectPic !== '') {
                return (
                  <div
                    style="width:60px;height:60px;padding:4px;cursor:pointer"
                    on-click={() => this.checkPicInfo(row)}
                  >
                    <img
                      style="width:100%;height:100%"
                      src={this.getImgUrl(
                        row.servicePath,
                        row.inspectPic.indexOf(',') != -1
                          ? row.inspectPic.split(',')[0]
                          : row.inspectPic
                      )}
                    />
                  </div>
                )
              }
              return <span>--</span>
            },
          },
          {
            title: '养殖场名称',
            tooltip: true,
            align: 'center',
            key: 'companyName',
            minWidth: 140,
          },
          {
            title: '设备名称',
            minWidth: 160,
            align: 'center',
            key: 'deviceName',
          },
          {
            title: '检查日期',
            minWidth: 140,
            align: 'center',
            key: 'inspectTime',
            render: (h, { row }) => {
              let str = row.inspectTime ? row.inspectTime.substring(0, 10) : ''
              return <span>{str}</span>
            },
          },
          {
            title: '检查人',
            minWidth: 140,
            align: 'center',
            key: 'inspectPeople',
          },
          {
            title: '检查人电话',
            minWidth: 140,
            align: 'center',
            key: 'inspectTel',
          },
          {
            title: '巡检状况',
            align: 'center',
            key: 'deviceCondition',
            tooltip: true,
            ellipsis: true,
            minWidth: 140,
          },
          {
            title: '是否损坏',
            align: 'center',
            minWidth: 140,
            key: 'deviceStatus',
            render: (createEle, params) => {
              switch (params.row.deviceStatus) {
                case '0':
                  return createEle(
                    'span',
                    {
                      style: {
                        color: 'green',
                      },
                    },
                    '正常'
                  )
                case '1':
                  return createEle(
                    'span',
                    {
                      style: {
                        color: 'red',
                      },
                    },
                    '已损坏'
                  )
              }
            },
          },
          {
            title: '上报人',
            minWidth: 140,
            tooltip: true,
            align: 'center',
            key: 'userName',
          },
          {
            title: '上报时间',
            minWidth: 140,
            tooltip: true,
            align: 'center',
            key: 'createTime',
          },
          {
            title: '备注',
            align: 'center',
            key: 'remarks',
            minWidth: 100,
            tooltip: true,
            ellipsis: true,
          },
          {
            title: '操作',
            width: 280,
            fixed: 'right',
            render: (h, { row }) => {
              return (
                <div>
                  {<a onClick={() => this.expert(row)}>导出报告</a>}
                  {this.CA('maintenanceInspection_record') && (
                    <a
                      style="margin-left:10px"
                      on-click={() => this.driverBind(row)}
                    >
                      维护记录
                    </a>
                  )}
                  {this.CA('maintenanceInspection_edit') && (
                    <a style="margin-left:10px" on-click={() => this.edit(row)}>
                      编辑
                    </a>
                  )}
                  {this.CA('maintenanceInspection_view') && (
                    <a
                      style="margin-left: 10px"
                      onClick={() => this.checkInfo(row)}
                    >
                      详情
                    </a>
                  )}
                  {this.CA('maintenanceInspection_delete') && (
                    <Poptip
                      confirm
                      transfer
                      title="确定删除吗?"
                      on-on-ok={() => this.delete(row.id)}
                    >
                      <a style="margin-left: 10px">删除</a>
                    </Poptip>
                  )}
                </div>
              )
            },
          },
        ],
        data: [],
        loading: false,
      },
      page: {
        pageNo: 1,
        pageSize: 10,
        total: 0,
      },
      search_data: {},
      modal: {
        title: '',
        show: false,
        submitLoading: false,
      },
      form: {
        id: '',
        areaName: '',
        inspectPic: '',
        deviceName: '',
        inspectTime: '',
        inspectPeople: '',
        inspectTel: '',
        deviceStatus: '',
        deviceCondition: '',
        remarks: '',
      },

      rules: {
        deviceName: [{ required: true, message: '请填写设备名称' }],

        inspectTime: [{ required: true, message: '请选择检查日期' }],
        inspectPeople: [{ required: true, message: '请填写检查人名称' }],
        deviceStatus: [{ required: true, message: '请选择是否损坏' }],
        inspectTel: [
          { required: true, message: '请填写检查人电话' },
          {
            validator(rule, value, callback) {
              if (!/^1[3|4|5|7|8|9][0-9]{9}$/.test(value)) {
                callback(rule.message)
                return
              }
              callback()
            },
            message: '手机号格式不正确',
            trigger: 'change',
          },
        ],
        farmerAddress: [{ required: true, message: '请填写养殖地址' }],
        scaleInfo: [{ required: true, message: '请选择规模类型' }],
        typeInfo: [{ required: true, message: '请选择养殖品种' }],
      },
      picture: {
        show: false,
        src: '',
      },
      farmersDetail: {
        show: false,
        info: {},
      },
      farmListInfo: [],
      servicePathInfo: '',
      deleteId: '',
      isDelete: false,
      imgSrc: '',
      checkPic: false,
      driverDetail: {
        show: false,
        info: {},
      },
      //省市区列表
      areaList: [],
    }
  },
  computed: {
    ...mapState({
      user: (state) => state.user.user,
    }),
    config() {
      let config = {
        filter: {
          // add: {
          //   addBtnName: '新增',
          //   ca: 'maintenanceInspection_add',
          // },
          loadExpend: {
            loadExpendName: '导出',
            ca: 'maintenanceInspection_export',
          },
          width: 220,
          filterBox: [
            {
              conditionName: '设备名称',
              component: 'input',
              field: 'deviceName',
              defaultValue: '',
            },
            {
              conditionName: '上报时间',
              component: 'dateShortcuts',
              type: 'daterange',
              field: 'createTime',
            },
            {
              conditionName: '检查日期',
              component: 'date',
              type: 'daterange',
              field: 'inspectTime',
              defaultValue: '',
            },
            {
              conditionName: '镇街',
              component: 'cascader',
              field: 'areaCode',
              defaultValue: [],
              data: this.areaList,
              isLoadData: true,
              loadData: this.loadData,
            },
          ],
        },
        page: this.page,
      }
      return config
    },
  },
  methods: {
    getList() {
      if (!this.CA('maintenanceInspection_check')) return
      this.table.loading = true
      this.$post(this.$api.DEVICE_RECORD.LIST, {
        pageNo: this.page.pageNo,
        pageSize: this.page.pageSize,
        ...this.search_data,
      })
        .then((res) => {
          this.table.data = res.list
          this.page.total = +res.total
        })
        .finally(() => {
          this.table.loading = false
        })
    },
    search(value) {
      console.log(value)
      let n
      if (value.areaCode) {
        for (let i = 0; i < value.areaCode.length; i++) {
          n = value.areaCode[value.areaCode.length - 1]
        }
        let obj = {
          ...value,
          areaCode: n,
        }
        this.search_data = obj
        this.getList()
      } else {
        let obj = {
          ...value,
        }
        this.search_data = obj
        this.getList()
      }
    },
    add() {
      this.modal = {
        show: true,
        title: '新增',
        submitLoading: false,
      }
    },
    edit(row) {
      for (let key in this.form) {
        this.form[key] = row[key]
      }
      this.form.inspectPic = this.getImgUrl(row.servicePath, row.inspectPic)
      this.modal = {
        show: true,
        title: '编辑',
        submitLoading: false,
      }
    },
    delete(id) {
      this.isDelete = true
      this.deleteId = id
    },
    deletes() {
      this.$post(this.$api.DEVICE_RECORD.DELETE, {
        id: this.deleteId,
      }).then(() => {
        this.$Message.success('删除成功！')
        this.getList()
      })
    },
    submit() {
      this.$refs.form.validate().then((res) => {
        if (!res) return
        let params = { ...this.form }
        let url
        if (params.id) {
          url = this.$api.DEVICE_RECORD.UPDATE
        } else {
          url = this.$api.DEVICE_RECORD.ADD
        }
        this.modal.submitLoading = true
        this.$post(url, params)
          .then(() => {
            this.$Message.success(params.id ? '修改成功！' : '增加成功！')
            this.getList()
            this.modal.show = false
          })
          .finally(() => {
            this.modal.submitLoading = false
          })
      })
    },
    pageChange(pageNo) {
      this.page.pageNo = pageNo
      this.getList()
    },
    pageSizeChange(pageSize) {
      this.page.pageSize = pageSize
      this.getList()
    },
    modalChange(visible) {
      if (visible) return
      this.form = {
        id: '',
        areaName: '',
        inspectPic: '',
        deviceName: '',
        inspectTime: '',
        inspectPeople: '',
        inspectTel: '',
        deviceStatus: '',
        deviceCondition: '',
        remarks: '',
      }
      this.$refs.form.resetFields()
    },
    //省市区列表接口
    getAreaList() {
      this.$post(this.$api.AREA_MANAGE.LIST, {
        parentId: '500100',
      }).then((res) => {
        res.forEach((item) => {
          item.label = item.name
          item.value = item.id
          if (item.hasChild == 'true') {
            item.loading = false
            item.children = []
          }
        })
        this.areaList = res
      })
    },
    //单条导出
    expert(row) {
      let url = this.$api.EXPORT.DEVICEONE + `?id=${row.id}`
      this.$downloads(url, `${row.companyName}设施设备检查维修记录报告`)
    },
    loadData(item, callback) {
      item.loading = true
      this.$post(this.$api.AREA_MANAGE.LIST, {
        parentId: item.id,
      })
        .then((res) => {
          res.forEach((item) => {
            item.label = item.name
            item.value = item.id
          })
          item.children = res
          callback()
        })
        .finally(() => {
          item.loading = false
        })
    },

    lookPicture(url) {
      this.picture = {
        show: true,
        src: url,
      }
    },
    //查看图片
    checkPicInfo(row) {
      this.checkPic = true
      this.imgSrc =
        row.inspectPic.indexOf(',') != -1
          ? row.inspectPic.split(',')[0]
          : row.inspectPic
    },
    //导出
    loadExpend() {
      let params = {
        pageNo: this.page.pageNo,
        pageSize: this.page.pageSize,
        ...this.search_data,
      }
      let url = this.$api.EXPORT.DEVICE
      this.$download(url, params, '设备设施维护记录表')
    },
    //详情
    checkInfo(row) {
      this.farmersDetail = {
        show: true,
        info: row,
      }
    },

    driverBind(row) {
      this.driverDetail = {
        show: true,
        info: row,
      }
    },
  },
  mounted() {
    this.getList()
    this.getAreaList()
  },
}
</script>

<style lang="less" scoped>
.outer-page {
  width: 100%;
  height: 100%;
}
</style>
