<template>
  <div>
    <Modal :title="title" :value="value" @input="(val) => $emit('input', val)" :width="800" @on-visible-change="modalShow">
      <Form :label-width="130" ref="form" :model="form" :rules="rules">
        <Row>
          <Col span="24">
          <FormItem label="维护人姓名" prop="maintenanceName">
            <Input :readonly="defaultEdeit" placeholder="请填写维护人姓名" v-model="form.maintenanceName" style="width: 80%"></Input>
          </FormItem>
          </Col>
          <Col span="24">
          <FormItem label="维护人电话" prop="maintenancePhone">
            <Input :readonly="defaultEdeit" placeholder="请填写维护人电话" v-model="form.maintenancePhone" style="width: 80%"></Input>
          </FormItem>
          </Col>
        </Row>
        <Row>
          <Col span="24">
          <FormItem label="维护时间" prop="maintenanceTime">
            <DatePicker type="date" :readonly="defaultEdeit" placeholder="请选择维护时间" v-model="form.maintenanceTime" style="width: 80%"></DatePicker>
          </FormItem>
          </Col>
          <Col span="24">
          <FormItem label="维护情况" prop="maintenanceDesc">
            <Input type="textarea" :rows="4" :readonly="defaultEdeit" placeholder="请填写维护情况" v-model="form.maintenanceDesc" style="width: 80%"></Input>
          </FormItem>
          </Col>
        </Row>
        <Row>
          <Col span="24">
          <FormItem label="备注" prop="remarks">
            <Input type="textarea" :rows="4" :readonly="defaultEdeit" placeholder="请填写备注" v-model="form.remarks" style="width: 80%"></Input>
          </FormItem>
          </Col>
        </Row>
      </Form>
      <p slot="footer" style="text-align: center">
        <Button type="primary" @click="submit" v-if="!defaultEdeit">提交</Button>
        <Button @click="() => $emit('input', false)">取消</Button>
      </p>
    </Modal>
    <pic-view v-model="checkPic" :src="imgdata"></pic-view>
  </div>
</template>

<script>
import moment from 'moment'
import picView from '@/components/picture_view'
export default {
  name: '',

  props: {
    value: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    DefluteValue: {
      type: Object,
      default: () => {
        return {}
      },
    },
    driverInfo: {
      type: Object,
      default: () => {
        return {}
      },
    },
    defaultEdeit: Boolean,
  },

  components: {
    // carDetail,
    picView,
  },
  data() {
    return {
      config: {
        filter: {
          add: {
            addBtnName: '新增记录',
            ca: 'driver_add',
          },
          width: 200,
          filterBox: [
            {
              conditionName: '姓名',
              component: 'input',
              field: 'name',
              defaultValue: '',
            },
          ],
        },
        page: {
          pageNo: 1,
          pageSize: 10,
          total: 0,
        },
      },
      deptList: [],
      imgdata: '',
      // modal: {
      //   show: false,
      //   title: "",
      //   submitLoading: false,
      // },
      // carDetail: {
      //   show: false,
      //   info: {},
      // },
      form: {},
      rules: {
        maintenanceName: [{ required: true, message: '请输入维护人姓名' }],
        maintenancePhone: [
          { required: true, message: '请输入维护人电话' },
          {
            validator(rule, value, callback) {
              if (!/^1[3|4|5|7|8|9][0-9]{9}$/.test(value)) {
                callback(rule.message)
                return
              }
              callback()
            },
            message: '手机号格式不正确',
            trigger: 'change',
          },
        ],
        maintenanceTime: [{ required: true, message: '请选择维护时间' }],
        maintenanceDesc: [{ required: true, message: '请输入维护情况' }],
      },
      //图片弹框
      checkPic: false,
    }
  },
  methods: {
    submit() {
      let params = { ...this.form }
      let url
      if (params.id) {
        url = this.$api.DEVICE_RECORD_LOG.UPDATE
      } else {
        url = this.$api.DEVICE_RECORD_LOG.ADD
        params.inspectId = this.driverInfo.id
      }
      this.$refs.form.validate().then((res) => {
        if (!res) return
        this.$post(url, {
          ...params,
        })
          .then(() => {
            this.$Message.success(params.userId ? '编辑成功！' : '添加成功！')
            this.DriverModel = false
            this.$emit('input', false)
            this.$emit('refrestList')
          })
          .catch((e) => {
            console.log(e)
          })
      })
    },
    //获取部门列表
    getDeptList() {
      this.$post(this.$api.company.BARNCHTREE)
        .then((res) => {
          this.deptList = res
        })
        .catch((e) => {
          console.log(e)
        })
    },
    //查看图片
    checkImg() {
      this.checkPic = true
    },
    modalShow(visible) {
      this.form = this.DefluteValue
    },
  },
  mounted() {
    this.getDeptList()
  },
}
</script>

<style lang="less" scoped>
</style>