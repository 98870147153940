<template>
  <div>
    <Modal :value="value" title="维护记录" @input="(val) => $emit('input', val)" :width="800" fullscreen @on-visible-change="modalChange">
      <x-table :no-data-text=" '暂无数据'" :columns="table.columns" :data="table.data" :loading="table.loading" :config="config" @search="search" @add="add" @page-change="pageChange" @page-size-change="pageSizeChange"></x-table>

      <car-detail v-model="DriverModel" :title="DriverTitle" :DefluteValue="DefluteValue" :defaultEdeit="defaultEdeit" @refrestList="refrestList" :driverInfo="driverInfo"></car-detail>
      <pic-view v-model="checkPic" :src="imgSrc"></pic-view>
      <p slot="footer">
        <Button @click="() => $emit('input', false)">取消</Button>
      </p>
    </Modal>
  </div>
</template>

<script>
import moment from 'moment'
import carDetail from './farmersModel'
import picView from '@/components/picture_view'
export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    driverInfo: {
      type: Object,
      default: () => {
        return {}
      },
    },
  },
  name: '',
  components: {
    carDetail,
    picView,
  },
  data() {
    return {
      DriverModel: false,
      DriverTitle: '',
      DefluteValue: {},
      table: {
        columns: [
          {
            title: '维修人',
            align: 'center',
            key: 'maintenanceName',
          },
          {
            title: '维修电话',
            align: 'center',
            key: 'maintenancePhone',
          },

          {
            title: '维修情况',
            align: 'center',
            key: 'maintenanceDesc',
          },
          {
            title: '备注',
            width: 130,
            key: 'remarks',
          },
          {
            title: '操作',
            width: 220,
            render: (h, { row }) => {
              return (
                <div>
                  {
                    <a
                      style="margin-left: 10px"
                      onClick={() => this.checkInfo(row)}
                    >
                      详情
                    </a>
                  }
                </div>
              )
            },
          },
        ],
        data: [],
        loading: false,
      },
      config: {
        filter: {
          add: {
            addBtnName: '新增',
          },
          width: 220,
          filterBox: [
            {
              conditionName: '维修人姓名',
              component: 'input',
              field: 'maintenanceName',
              defaultValue: '',
            },
          ],
        },
        page: {
          pageNo: 1,
          pageSize: 10,
          total: 0,
        },
      },
      defaultEdeit: false,
      search_data: {},
      imgSrc: '',
      checkPic: false,
      inspectId: '',
    }
  },
  methods: {
    search(data) {
      this.search_data = data
      this.config.page.pageNo = 1
      this.getList()
    },

    add() {
      this.DriverModel = true
      this.DriverTitle = '添加维护记录'
      this.defaultEdeit = false
      this.DefluteValue = {}
    },

    pageChange(pageNo) {
      this.config.page.pageNo = pageNo
      this.getList()
    },
    refrestList() {
      this.getList()
    },
    pageSizeChange(pageSize) {
      this.config.page.pageSize = pageSize
      this.getList()
    },
    getList() {
      this.table.loading = true
      this.$post(this.$api.DEVICE_RECORD_LOG.LIST, {
        pageNo: this.config.page.pageNo,
        pageSize: this.config.page.pageSize,
        ...this.search_data,
        inspectId: this.inspectId,
      })
        .then((res) => {
          // console.log("====res.list", res.list);
          this.table.data = res.list
          this.config.page.total = +res.total
        })
        .finally(() => {
          this.table.loading = false
        })
    },
    //查看图片
    checkPicInfo(row) {
      this.checkPic = true
      this.imgSrc = row.servicePath + row.img
    },
    edit(row) {
      this.defaultEdeit = false
      this.DriverModel = true
      this.DriverTitle = '修改维护记录'
      this.DefluteValue = row
    },
    checkInfo(row) {
      console.log(row)
      this.defaultEdeit = true
      this.DefluteValue = row
      this.DriverModel = true
      this.DriverTitle = '维护记录详情'
    },

    delete(id) {
      this.$post(this.$api.DEVICE_RECORD_LOG.DELETE, {
        userId: id,
      }).then(() => {
        this.$Message.success('删除成功！')
        this.getList()
      })
    },
    modalChange(visible) {
      console.log(123123123)
      this.form = {
        inspectId: this.driverInfo.id,
        maintenanceName: '',
        maintenancePhone: '',
        maintenanceDesc: '',
        remarks: '',
      }
    },
  },
  watch: {
    driverInfo(n) {
      this.inspectId = n.id
      this.getList()
    },
  },
  mounted() {
    this.inspectId = this.driverInfo.id
    this.getList()
  },
}
</script>

<style lang="less" scoped>
</style>